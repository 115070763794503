<template>
  <v-container fluid>
    <!-- タブの外にエオルゼア時間とローカル時間を表示 -->
    <div class="d-flex justify-center">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            class="mx-2"
            label
            color="white"
            v-bind="attrs"
            v-on="on"
          >
            ET {{ eorzeaTime0 }}
          </v-chip>
        </template>
        <span> {{ $t('message.now_eorzea_time') }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            label
            color="white"
            variant="text"
            class="mx-2"
            v-bind="attrs"
            v-on="on"
          >
            LT {{ localTime0 }}
          </v-chip>
        </template>
        <span> {{ $t('message.now_local_time') }}</span>
      </v-tooltip>
    </div>

    <div class="d-flex justify-center my-2">
      <v-tooltip
        v-if="isWithinTimeRange(eorzeaTime0)"
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            color="white"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="orange">
              mdi-white-balance-sunny
            </v-icon>
            ET 06:00-19:59
          </v-chip>
        </template>
        <span> {{ $t('message.day_time') }}</span>
      </v-tooltip>
    </div>

    <div class="d-flex justify-center my-2">
      <v-tooltip
        v-if="!isWithinTimeRange(eorzeaTime0)"
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            color="white"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="purple">
              mdi-weather-night
            </v-icon>
            ET 20:00-05:59
          </v-chip>
        </template>
        <span> {{ $t('message.night_time') }}</span>
      </v-tooltip>
    </div>

    <v-row justify="center">
      <v-col
        cols="12"
        md="6"
      >
        <v-tabs
          v-model="tab"
        >
          <v-tab>LT → ET</v-tab>
          <v-tab>ET → LT</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <!-- ローカルからエオルゼアへの変換タブ -->
          <v-tab-item>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="inputLocalDate"
                      :label="$t('message.date')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="inputLocalDate"
                    no-title
                    scrollable
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(inputLocalDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="inputLocalTime"
                  :hint="$t('message.local_time_hint')"
                  persistent-hint
                  :label="$t('message.local_time')"
                  prepend-icon="mdi-clock-time-four-outline"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-alert
                  v-if="outputEorzeaTime"
                  border="top"
                  color="red"
                  outlined
                  type="success"
                  icon="mdi-clock"
                >
                  ET: {{ outputEorzeaTime }}
                </v-alert>
              </v-col>
            </v-row>
          </v-tab-item>

          <!-- エオルゼアからリアルへの変換タブ -->
          <v-tab-item>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="eorzeaInputTime"
                  :hint="$t('message.local_time_hint')"
                  persistent-hint
                  :label="$t('message.eorzea_time2')"
                  prepend-icon="mdi-clock-time-four-outline"
                  @change="convertToEorzeaToLocal"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-list dense>
                  <v-list-item
                    v-for="LT in convertedLocalTimes"
                    :key="LT"
                  >
                    <v-icon>mdi-clock</v-icon>

                    <v-list-item-content>LT: {{ LT }}</v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'EorzeaTime',
    metaInfo: {
      title: 'EorzeaTime',
      titleTemplate: '%s | FFXIV Housing Eden',
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { property: 'og:url', content: 'https://ff14eden.work' },
        { property: 'og:title', content: 'FFXIV Housing Eden' },
        {
          name: 'description',
          content: 'FFXIV Housing EdenはFFXIV(ファイナルファンタジー14)でのハウジング情報を配信するサイトです。',
        },
        {
          property: 'og:image',
          content: 'https://ff14eden.work/twitter_card.jpg?3',
        },
      ],
    },
    data () {
      return {
        userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        timeZones: [
          'UTC',
          'Europe/London', // 英国
          'Europe/Paris', // フランス
          'Europe/Berlin', // ドイツ
          'Europe/Athens', // ギリシャ
          'America/New_York', // アメリカ東部
          'America/Chicago', // アメリカ中部
          'America/Denver', // アメリカ山岳部
          'America/Los_Angeles', // アメリカ太平洋
          'America/Toronto', // カナダ東部
          'America/Edmonton', // カナダ山岳部
          'Asia/Tokyo', // 日本
          'Asia/Seoul', // 韓国
          'Asia/Singapore', // シンガポール
          'Australia/Sydney', // オーストラリア
          'Asia/Shanghai', // 中国
          // 必要に応じて他のタイムゾーンを追加
        ],
        tab: 0,
        menu: false,
        menu2: false,
        inputLocalDate: null,
        inputLocalTime: null,
        localTime0: null,
        inputEorzeaTime: null,
        outputEorzeaTime: null,
        eorzeaTime0: null,
        eorzeaInputTime: null,
        convertedLocalTimes: [],
        eorzeaMultiplier: 3600 / 175,
      }
    },
    watch: {
      inputLocalDate (newVal) {
        if (newVal && this.inputLocalTime) {
          this.convertToLocalToEorzea()
        }
      },
      inputLocalTime (newVal) {
        if (newVal && this.inputLocalDate) {
          this.convertToLocalToEorzea()
        }
      },
      eorzeaInputTime (newVal) {
        if (newVal) {
          this.convertToEorzeaToLocal()
        }
      },
    },
    mounted () {
      // タイマーをセットアップして1秒ごとにデータを更新
      this.timer = setInterval(this.updateTimes, 1000)
    },
    beforeDestroy () {
      // コンポーネントがアンマウントされるときにタイマーをクリア
      clearInterval(this.timer)
    },
    methods: {
      isWithinTimeRange (time) {
        if (!time) return false
        const [hours, minutes] = time.split(':')
        const timeInMinutes = parseInt(hours) * 60 + parseInt(minutes)
        const start = 6 * 60 // 06:00 in minutes
        const end = 19 * 60 + 59 // 19:59 in minutes
        return timeInMinutes >= start && timeInMinutes <= end
      },
      convertUtcToEorzeaTime (utcDate) {
        // エオルゼア時間の倍率を定義
        const eorzeaMultiplier = 3600 / 175

        // UTC時刻をエオルゼア時刻に変換
        const eorzeaTime = new Date(utcDate.getTime() * eorzeaMultiplier)

        // 時、分、秒を取得し、ゼロ埋めしてフォーマット
        const hours = eorzeaTime.getUTCHours().toString().padStart(2, '0')
        const minutes = eorzeaTime.getUTCMinutes().toString().padStart(2, '0')
        const seconds = eorzeaTime.getUTCSeconds().toString().padStart(2, '0')

        // フォーマットされた時間を返す
        return `${hours}:${minutes}:${seconds}`
      },
      updateTimes () {
        // 現在のエオルゼア時間を計算
        const now = new Date()
        const eorzeaMultiplier = 3600 / 175
        const eorzeaTimestamp = new Date(now.getTime() * eorzeaMultiplier)
        this.eorzeaTime0 = eorzeaTimestamp
          .getUTCHours()
          .toString()
          .padStart(2, '0') +
          ':' +
          eorzeaTimestamp
            .getUTCMinutes()
            .toString()
            .padStart(2, '0') +
          ':' +
          eorzeaTimestamp
            .getUTCSeconds()
            .toString()
            .padStart(2, '0')

        this.localTime0 = new Intl.DateTimeFormat(undefined, {
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: false,
        }).format(new Date())
      },
      getTimeZoneOffset (timeZone) {
        // 現在の日時を指定されたタイムゾーンでの日時に変換
        const now = new Date()
        const timeZoneDateTime = new Date(now.toLocaleString('en-US', { timeZone }))

        // UTCとの時差を計算
        const offset = (timeZoneDateTime - now) / 60000
        return offset
      },
      refreshResults () {
        // もし現在のエオルゼア時間またはローカル時間のデータがあれば、それを用いて再計算する
        if (this.inputEorzeaTime) {
          // エオルゼア時間からローカル時間への再計算
          this.convertToEorzeaToLocal()
        }

        if (this.inputLocalDate && this.inputLocalTime) {
          // ローカル時間からエオルゼア時間への再計算
          this.convertToLocalToEorzea()
        }
      },
      getUserTimeZone () {
        // ローカルストレージからユーザーのタイムゾーン設定を読み込む
        return localStorage.getItem('userTimeZone') || null
      },

      saveUserTimeZone (timeZone) {
        // ローカルストレージにユーザーのタイムゾーン設定を保存
        localStorage.setItem('userTimeZone', timeZone)
      },
      formatDateTime (dateTime) {
        const locale = this.$i18n.locale
        const options = {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: false,
        }
        return new Intl.DateTimeFormat(locale, options).format(dateTime)
      },
      convertToLocalToEorzea () {
        if (!this.inputLocalDate || !this.inputLocalTime) {
          // 無効な日付または時間が指定された場合、処理を中断
          return
        }

        // 時間が HHmm 形式または HH:mm 形式であるかチェック
        const timeFormatCheck = /^(\d{2}):?(\d{2})$/.test(this.inputLocalTime)
        if (!timeFormatCheck) {
          this.outputEorzeaTime = null
          return // 時間形式が無効な場合、処理を中断
        }

        // 時間が HHmm 形式の場合、HH:mm 形式に変換
        if (this.inputLocalTime.length === 4 && /^\d{4}$/.test(this.inputLocalTime)) {
          this.inputLocalTime = this.inputLocalTime.replace(/(\d{2})(\d{2})/, '$1:$2')
        }

        const eorzeaMultiplier = 3600 / 175
        const inputLocalDateTime = new Date(`${this.inputLocalDate}T${this.inputLocalTime}`)
        const eorzeaTime = new Date(inputLocalDateTime.getTime() * eorzeaMultiplier)
        this.outputEorzeaTime = eorzeaTime.getUTCHours() + ':' + eorzeaTime.getUTCMinutes()
        this.outputEorzeaTime = eorzeaTime
          .getUTCHours()
          .toString()
          .padStart(2, '0') +
          ':' +
          eorzeaTime
            .getUTCMinutes()
            .toString()
            .padStart(2, '0') +
          ':' +
          eorzeaTime
            .getUTCSeconds()
            .toString()
            .padStart(2, '0')
      },

      handleEorzeaTimeChange () {
        if (this.eorzeaInputTime && this.eorzeaInputTime.length === 5 && this.eorzeaInputTime.includes(':')) {
          this.convertToEorzeaToLocal()
        }
      },

      convertToEorzeaToLocal () {
        if (!this.eorzeaInputTime) {
          return // eorzeaInputTime が無効な場合、処理を中断
        }

        // 時間が HHmm 形式の場合、HH:mm 形式に変換
        let eorzeaTime = this.eorzeaInputTime
        if (eorzeaTime.length === 4 && /^\d{4}$/.test(eorzeaTime)) {
          eorzeaTime = eorzeaTime.replace(/(\d{2})(\d{2})/, '$1:$2')
          this.eorzeaInputTime = eorzeaTime
        }

        // eorzeaTime が正しい形式であるか確認（HH:mm）
        const eorzeaTimeParts = eorzeaTime.split(':')
        if (eorzeaTimeParts.length === 2 && eorzeaTimeParts[0].length === 2 && eorzeaTimeParts[1].length === 2) {
          const eorzeaHours = parseInt(eorzeaTimeParts[0])
          const eorzeaMinutes = parseInt(eorzeaTimeParts[1])
          this.convertedLocalTimes = []

          const now = new Date()
          const eorzeaMultiplier = 3600 / 175

          // 現在のETを計算
          const currentEorzeaTime = new Date(now.getTime() * eorzeaMultiplier)
          const eorzeaDayMinutes = currentEorzeaTime.getUTCHours() * 60 + currentEorzeaTime.getUTCMinutes()
          let targetEorzeaDayMinutes = eorzeaHours * 60 + eorzeaMinutes

          // 現在のETが目標ETを過ぎていた場合、次の日に調整
          if (eorzeaDayMinutes > targetEorzeaDayMinutes) {
            targetEorzeaDayMinutes += 1440 // 1日分の分を加算
          }

          // 次の100回のETに対応するリアルタイムを計算
          for (let i = 0; i < 300 && this.convertedLocalTimes.length < 300; i++) {
            const minutesUntilTarget = targetEorzeaDayMinutes - eorzeaDayMinutes
            const realTimeUntilTarget = minutesUntilTarget * (175 / 3600) * 60 * 1000 // リアルタイムのミリ秒単位での差

            const nextRealTime = new Date(now.getTime() + realTimeUntilTarget)
            if (!isNaN(nextRealTime.getTime())) {
              this.convertedLocalTimes.push(this.formatDateTime(nextRealTime))
            }

            targetEorzeaDayMinutes += 1440 // 次の日の同じETに対応するために1日分加算
          }
        } else {
          //   this.convertedLocalTimes = ['無効な時間形式']
          this.convertedLocalTimes = []
        }
      },
    },
  }
</script>
